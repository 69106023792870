<template>
  <div class="flex items-center justify-around gap-x-4">
    <div class="size-[38px] min-w-0 overflow-hidden rounded-full">
      <img :src="customerLogo" class="object-contain" :alt="siteName" />
    </div>
    <div class="min-w-0 flex-1">
      <p class="text-sm font-semibold text-gray-900">
        {{ siteName }}
      </p>
      <p class="text-xs text-gray-500">
        {{ siteAddress }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SiteListItem',

    props: {
      customerLogo: {
        type: String,
        required: true,
      },

      siteAddress: {
        type: String,
        default: '',
      },

      siteName: {
        type: String,
        default: '',
      },
    },
  })
</script>
