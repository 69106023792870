<template>
  <div>
    <TitleBanner title="Select Site" />
    <div v-if="!isVisitor" class="mt-4 px-4">
      <PrimaryButton
        class="bypass"
        type="button"
        action="I'M NOT ON SITE (BYPASS)"
        @click.once="bypass"
      />
    </div>
    <div class="px-2">
      <section class="flow-root divide-y divide-gray-200">
        <div v-for="site in sites" :key="site.id" class="">
          <button
            type="button"
            class="w-full px-2 py-4 text-left"
            @click="selectSite(site)"
          >
            <SiteListItem
              :site-address="site.address"
              :site-name="site.name"
              :customer-logo="getCustomerLogo(site.customer)"
            />
          </button>
        </div>
      </section>
    </div>
    <LoadingView :is-loading="loading" :message="loadingMessage" />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import { Customer, getNearbySites, Site } from '@/services/api/site'
  import { useRouter } from 'vue-router'
  import config from '@/config'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import SiteListItem from '@/components/SiteListItem.vue'
  import { resetStoreExceptUser } from '@/components/use/resetStore'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import Log from '@/utilities/Log'
  import customerLogoMap from '@/utilities/customerLogo'
  import { getPlate } from '@/services/api/plate'
  import { getCurrentEventRoute } from '@/utilities/session'
  import handleError from '@/utilities/handleError'
  import ReportableException from '@/exceptions/ReportableException'
  import LoadingView from '@/views/LoadingView.vue'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useNativeScanStore } from '@/storage/nativeScan'
  import { useUserPositionStore } from '@/storage/userPosition'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'SelectNearbySite',
    components: {
      LoadingView,
      PrimaryButton,
      SiteListItem,
      TitleBanner,
    },

    async setup() {
      const router = useRouter()
      const { setSite, setSelectionConfirmed, resetSite } = useSiteStore()
      const { name: siteName, id: siteId } = storeToRefs(useSiteStore())
      const { id: userId, isUserStoreDefault } = storeToRefs(useUserStore())
      const { position } = storeToRefs(useUserPositionStore())
      const { setCustomerInfo, resetCustomer } = useCustomerStore()
      const { isVisitor } = storeToRefs(useUserLogin())
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const { setUuid, resetNativeScan } = useNativeScanStore()
      const { uuid: plateUuid } = storeToRefs(useNativeScanStore())
      const sites = ref<Site[]>([])
      const loading = ref(false)
      const noResult = computed(() => {
        return sites.value.length === 0 && !loading.value
      })
      const loadingMessage = ref('Loading...')

      onMounted(() => {
        setSelectionConfirmed(false)
      })

      let isLoaded = false

      if (plateUuid.value?.length === 36) {
        loadingMessage.value = 'Loading site...'
        isLoaded = await loadSiteByPlate(plateUuid.value)
      }
      if (!isLoaded) {
        loadingMessage.value = 'Loading sites...'
        await loadSites()
      }

      async function bypass(): Promise<void> {
        await router.replace({ name: 'bypass' })
      }

      async function loadSiteByPlate(uuid: string): Promise<boolean> {
        try {
          const plate = await getPlate(uuid)

          if (plate.site.id === siteId.value) {
            await router.replace(getCurrentEventRoute())
            return true
          }

          Log.storeResetExcludingUser('selectNearbySite.ts', 'getPlate')
          resetStoreExceptUser()
          // reinstate uuid as required to confirm site
          setUuid(uuid)
          setSite(plate.site)
          setCustomerInfo(plate.site.customer)

          await router.replace({ name: 'confirmNearbySite' })

          return true
        } catch (error) {
          await handlePlateNotFoundError(error)

          return false
        }
      }

      async function handlePlateNotFoundError(error: Error | unknown) {
        resetSite()
        resetCustomer()
        resetNativeScan()

        Log.siteStoreReset('selectNearbySite.ts', 'getPlate')
        Log.customerStoreReset('selectNearbySite.ts', 'getPlate')

        await handleError(
          new ReportableException(
            (error as Error).message ?? 'An unknown error occurred.',
            { error: error },
            ['SelectNearbySite', 'GetPlate'],
          ),
        )
      }

      async function loadSites(): Promise<void> {
        loading.value = true

        if (isUserStoreDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              userId: userId.value,
              userLocation: position.value,
            },
            ['InvalidStore', 'FetchNearbySites'],
          )
        }

        const response = await getNearbySites(
          userId.value.toString(),
          position.value,
          config.distance.radius.user,
        )

        if (response) {
          sites.value = response
        }
        loading.value = false
      }

      async function selectSite(site: Site): Promise<void> {
        setSite(site)
        setCustomerInfo(site.customer)

        await router.replace({ name: 'confirmNearbySite' })
      }

      function getCustomerLogo(customer: Customer): string {
        return (
          customerLogoMap.get(customer.name ?? '') ??
          (customerLogoMap.get('default') as string)
        )
      }

      return {
        bypass,
        getCustomerLogo,
        isVisitor,
        loading,
        loadingMessage,
        noResult,
        selectSite,
        siteName,
        sites,
      }
    },
  })
</script>
